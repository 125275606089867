import mock from "./mock";

import "./pages/faq";
import "./apps/chat";
import "./apps/todo";
import "./apps/email";
import "./apps/invoice";
import "./apps/calendar";
import "./apps/eCommerce";
import "./pages/blog-data";
import "./navbar/navbarSearch";
import "./pages/knowledge-base";
import "./autoComplete/autoComplete";

mock.onAny().passThrough();
