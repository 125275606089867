// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import todo from "@src/views/todo/store";
import chat from "@src/views/chat/store";
import email from "@src/views/email/store";
import invoice from "@src/views/invoice/store";
import calendar from "@src/views/calendar/store";
import ecommerce from "@src/views/ecommerce/store";
import permissions from "@src/views/roles-permissions/store";

const rootReducer = {
  todo,
  chat,
  email,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  permissions,
};

export default rootReducer;
