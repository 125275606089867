import { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";

let firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
//connectAuthEmulator(auth, "http://localhost:9099");
export const db = initializeFirestore(app, { localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }) });

//connectFirestoreEmulator(db, "localhost", 8080);
/*enableIndexedDbPersistence(db).catch((err) => {
  if (err.code == "failed-precondition") {
    console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.");
  } else if (err.code == "unimplemented") {
    console.log("The current browser does not support all of the features required to enable persistence...");
  }
});*/
export const fun = getFunctions(app);
//connectFunctionsEmulator(fun, "localhost", 5051);
export const store = getStorage(app);
//connectStorageEmulator(store, "localhost", 9199);
