import React, { useContext, useState, useEffect, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db, fun, store } from "./firebaseConfig";
import { httpsCallable } from "firebase/functions";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  collection,
  onSnapshot,
  doc,
  addDoc,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  query,
  where,
  Timestamp,
  serverTimestamp,
  clearIndexedDbPersistence,
  terminate,
} from "firebase/firestore";
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateEmail,
  signOut,
} from "firebase/auth";
// ** Spinner (Splash Screen)
import Spinner from "@components/spinner/Fallback-spinner";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [connection, setConnection] = useState({ status: true, msg: "" });
  const [currentUser, setCurrentUser] = useState();
  const [userData, setUserData] = useState();
  const [users, setUsers] = useState();
  const [notifications, setNotifications] = useState();
  const [businesses, setBusinesses] = useState();
  const [industries, setIndustries] = useState();
  const [packages, setPackages] = useState();
  const [regions, setRegions] = useState();
  const [ports, setPorts] = useState();
  const [payTerms, setPayTerms] = useState();
  const [shipDocs, setShipDocs] = useState();
  const [status, setStatus] = useState();
  const [roles, setRoles] = useState();
  const [modules, setModules] = useState();
  const [permissions, setPermissions] = useState();
  const [terms, setTerms] = useState();
  const [tenders, setTenders] = useState();
  const [bids, setBids] = useState();
  const [transactions, setTransactions] = useState();
  const [loading, setLoading] = useState(true);
  const [subscribers, setSubscribers] = useState([]);

  function connect() {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        getData(user.uid);
      } else {
        console.log("There is no user");
        setLoading(false);
      }
    });
    return unsubscribe;
  }
  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  async function logout() {
    await terminate(db);
    await clearIndexedDbPersistence(db);
    subscribers.forEach((subscriber) => subscriber());
    localStorage.removeItem("userData");
    return signOut(auth).then(() => window.location.replace("/"));
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function changeEmail(email) {
    const user = auth.currentUser;
    return updateEmail(user, email);
  }

  function sendEmailVerification() {
    const user = auth.currentUser;
    return sendEmailVerification(user);
  }

  async function reauthenticate(currentPassword) {
    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(user.email, currentPassword);
    const result = await reauthenticateWithCredential(user, cred);
    return result;
  }

  async function changePassword(newPassword) {
    const user = auth.currentUser;
    const result = await updatePassword(user, newPassword);
    return result;
  }

  function passwordReset(data) {
    const sendPasswordReset = httpsCallable(fun, "sendPasswordReset");
    return sendPasswordReset(data);
  }
  /*function updatePassword(password) {
    return updatePassword(password);
  }*/

  async function upload(path, data) {
    const storageRef = ref(store, path);
    // 'file' comes from the Blob or File API
    if (data) {
      const snapshot = await uploadBytes(storageRef, data);
      const url = await getDownloadURL(storageRef);
      return url;
    } else {
      const url = "";
      return url;
    }
  }

  function timestamp(date) {
    return Timestamp.fromDate(date);
  }
  async function addDocument(col, data) {
    return await addDoc(collection(db, col), { ...data, createdAt: serverTimestamp() });
  }
  async function updateDocument(col, data) {
    return await updateDoc(doc(db, col, data.id), { ...data, updatedAt: serverTimestamp() });
  }
  async function deleteDocument(col, data) {
    return await deleteDoc(doc(db, col, data.id));
  }

  //**Users */
  function getUser(uid) {
    return onSnapshot(
      doc(db, "Users", uid),
      (snapshot) => {
        setUserData({ ...snapshot.data(), uid: uid, ability: roles[snapshot.data()?.roleID]?.ability });
        localStorage.setItem("userData", JSON.stringify({ ...snapshot.data(), uid: uid, ability: roles[snapshot.data()?.roleID]?.ability }));
        setLoading(false);
      },
      (error) => {
        console.log("UserData: ", error);
      }
    );
  }
  function getUsers() {
    let users = query(collection(db, "Users"), orderBy("fullName"));
    return onSnapshot(
      users,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setUsers(col);
      },
      (error) => {
        console.log("Users: ", error);
      }
    );
  }
  function addUsers(data) {
    const addUser = httpsCallable(fun, "addUser");
    return addUser(data);
  }
  function updateUsers(data) {
    const updateUser = httpsCallable(fun, "updateUser");
    return updateUser(data);
  }
  function disableUsers(data) {
    const disableUser = httpsCallable(fun, "disableUser");
    return disableUser(data);
  }
  function deleteUsers(data) {
    const deleteUser = httpsCallable(fun, "deleteUser");
    return deleteUser(data);
  }

  //**Notifications */
  function getNotifications(uid) {
    let notifications = query(collection(db, `Users/${uid}/Notifications`), orderBy("createdAt", "desc"));
    return onSnapshot(
      notifications,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setNotifications(col);
      },
      (error) => {
        console.log("Notifications: ", error);
      }
    );
  }

  //**Businesses */

  function getBusinesses() {
    let businesses = query(collection(db, "Businesses"), orderBy("fullName"));
    return onSnapshot(
      businesses,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setBusinesses(col);
      },
      (error) => {
        console.log("Businesses: ", error);
      }
    );
  }

  //**Regions */
  function getRegions() {
    let regions = query(collection(db, "Regions"), orderBy("name"));
    return onSnapshot(
      regions,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setRegions(col);
      },
      (error) => {
        console.log("Regions: ", error);
      }
    );
  }

  //**Industries */
  function getIndustries() {
    let industries = query(collection(db, "Industries"), orderBy("name"));
    return onSnapshot(
      industries,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setIndustries(col);
      },
      (error) => {
        console.log("Industries: ", error);
      }
    );
  }

  //**Packages */
  function getPackages() {
    let packages = query(collection(db, "Packages"), orderBy("name"));
    return onSnapshot(
      packages,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setPackages(col);
      },
      (error) => {
        console.log("Packages: ", error);
      }
    );
  }

  //**Ports */
  function getPorts() {
    let ports = query(collection(db, "Ports"), orderBy("name"));
    return onSnapshot(
      ports,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setPorts(col);
      },
      (error) => {
        console.log("Ports: ", error);
      }
    );
  }

  //**PayTerms */
  function getPayTerms() {
    let payTerms = query(collection(db, "PayTerms"), orderBy("name"));
    return onSnapshot(
      payTerms,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setPayTerms(col);
      },
      (error) => {
        console.log("PayTerms: ", error);
      }
    );
  }

  //**ShipDocs */
  function getShipDocs() {
    let shipDocs = query(collection(db, "ShipDocs"), orderBy("name"));
    return onSnapshot(
      shipDocs,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setShipDocs(col);
      },
      (error) => {
        console.log("ShipDocs: ", error);
      }
    );
  }

  //**Status */
  function getStatus() {
    let status = query(collection(db, "Status"), orderBy("name"));
    return onSnapshot(
      status,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setStatus(col);
      },
      (error) => {
        console.log("Status: ", error);
      }
    );
  }

  //**Roles */
  function getRoles() {
    let roles = query(collection(db, "Roles"), orderBy("name"));
    return onSnapshot(
      roles,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setRoles(col);
      },
      (error) => {
        console.log("Roles: ", error);
      }
    );
  }

  //**Modules */
  function getModules() {
    let modules = query(collection(db, "Modules"), orderBy("name"));
    return onSnapshot(
      modules,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setModules(col);
      },
      (error) => {
        console.log("Modules: ", error);
      }
    );
  }

  //**Permissions */
  function getPermissions() {
    let permissions = query(collection(db, "Permissions"), orderBy("name"));
    return onSnapshot(
      permissions,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setPermissions(col);
      },
      (error) => {
        console.log("Permissions: ", error);
      }
    );
  }

  //**Terms */
  function getTerms() {
    let terms = query(collection(db, "Terms"), orderBy("createdAt", "desc"));
    return onSnapshot(
      terms,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setTerms(col);
      },
      (error) => {
        console.log("Terms: ", error);
      }
    );
  }

  //**Tenders */

  function getTenders() {
    let tenders = query(collection(db, "Tenders"), orderBy("expiredAt", "desc"));
    return onSnapshot(
      tenders,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setTenders(col);
      },
      (error) => {
        console.log("Tenders: ", error);
      }
    );
  }

  //**Bids */

  function getBids() {
    let bids = query(collection(db, "Bids"), orderBy("expiredAt", "desc"));
    return onSnapshot(
      bids,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setBids(col);
      },
      (error) => {
        console.log("Bids: ", error);
      }
    );
  }

  //**Transactions */

  function getTransactions() {
    let transactions = query(collection(db, "Transactions"), orderBy("createdAt", "desc"));
    return onSnapshot(
      transactions,
      (snapshot) => {
        let col = {};
        snapshot.forEach((doc) => (col[doc.id] = { ...doc.data(), id: doc.id }));
        setTransactions(col);
      },
      (error) => {
        console.log("Transactions: ", error);
      }
    );
  }

  /** Main */
  function getData(uid) {
    const users = getUsers();
    const business = getBusinesses();
    const tender = getTenders();
    const bid = getBids();
    const transaction = getTransactions();
    const region = getRegions();
    const industry = getIndustries();
    const pack = getPackages();
    const port = getPorts();
    const payTerm = getPayTerms();
    const shipDoc = getShipDocs();
    const statu = getStatus();
    const role = getRoles();
    const module = getModules();
    const permission = getPermissions();
    const term = getTerms();
    const notification = getNotifications(uid);

    subscribers.push(business);
    subscribers.push(users);
    subscribers.push(notification);
    subscribers.push(tender);
    subscribers.push(bid);
    subscribers.push(transaction);
    subscribers.push(role);
    subscribers.push(permission);
    subscribers.push(module);
    subscribers.push(statu);
    subscribers.push(region);
    subscribers.push(industry);
    subscribers.push(shipDoc);
    subscribers.push(pack);
    subscribers.push(payTerm);
    subscribers.push(term);
    subscribers.push(port);

    //setLoading(false);
  }

  useEffect(() => {
    var condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      console.log("ONLINE");
      fetch("https://www.google.com/", {
        // Check for internet connectivity
        mode: "no-cors",
      })
        .then(() => {
          console.log("CONNECTED TO INTERNET");
          setConnection({ status: true, msg: "CONNECTED TO INTERNET" });
          connect();
        })
        .catch(() => {
          console.log("INTERNET CONNECTIVITY ISSUE");
          setConnection({ status: false, msg: "INTERNET CONNECTIVITY ISSUE" });
        });
    } else {
      console.log("OFFLINE");
      setConnection({ status: false, msg: "OFFLINE" });
    }
  }, []);

  useEffect(() => {
    if (roles) {
      const user = getUser(currentUser.uid);
      subscribers.push(user);
    }
  }, [roles]);

  useEffect(() => {
    if (userData?.disabled) {
      console.log("Logout");
      logout();
    }
  }, [userData]);

  const value = {
    connection,
    login,
    signup,
    logout,
    upload,
    reauthenticate,
    resetPassword,
    changeEmail,
    changePassword,
    passwordReset,
    timestamp,

    addDocument,
    updateDocument,
    deleteDocument,

    userData,
    users,
    getUser,
    getUsers,
    addUsers,
    updateUsers,
    disableUsers,
    deleteUsers,

    notifications,
    getNotifications,

    businesses,
    getBusinesses,

    regions,
    getRegions,

    industries,
    getIndustries,

    packages,
    getPackages,

    ports,
    getPorts,

    shipDocs,
    getShipDocs,

    status,
    getStatus,

    payTerms,
    getPayTerms,

    roles,
    getRoles,

    modules,
    getModules,

    permissions,
    getPermissions,

    terms,
    getTerms,

    tenders,
    getTenders,

    bids,
    getBids,

    transactions,
    getTransactions,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
      {loading && <Spinner />}
    </AuthContext.Provider>
  );
}
